<template>
  <Carousel
    v-if="items && items.length > 0"
    :autoplay="true"
    :autoplayTimeout="5000"
    :loop="true"
    :speed="1500"
    :perPageCustom="perPageCustom"
    :scrollPerPage="false"
    :touchDrag="false"
    :mouseDrag="false"
    :navigationEnabled="true"
    navigationPrevLabel="<i class=&quot;fa fa-angle-double-left navigation-label font-30&quot;></i>"
    navigationNextLabel="<i class=&quot;fa fa-angle-double-right navigation-label font-30&quot;></i>"
    :paginationEnabled="!isHome && !isMobile"
    paginationActiveColor="#BE00FE"
    paginationPosition="bottom-overlay"
    >
    <Slide v-for="(item, index) in items" :key="(item, index)">
      <div class="w-100" v-if="isHome">
        <div class="w-100 pointer position-relative" @click="gotoEventDetail(item.eventParam1)">
          <img class="w-100" :height="isMobile ? 150 : 240" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl1" />
          <div class="slide-mask"></div>
        </div>
        <div class="w-100 pointer position-relative" @click="gotoEventDetail(item.eventParam2)">
          <img class="w-100" :height="isMobile ? 150 : 240" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 1 ? assets.testImage1 : assets.testImage2 : item.photoUrl2" />
          <div class="slide-mask"></div>
        </div>
      </div>
      <div class="w-100 pointer position-relative" @click="gotoEventDetail(item.eventParam)" v-else>
        <img class="w-100" :height="isMobile ? isLiveEvents ? 180 : 150 : isLiveEvents ? 360 : 240" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl" />
        <div class="slide-mask"></div>
      </div>
    </Slide>
  </Carousel>
</template>

<style scoped>
  .slide-mask {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  :deep(.navigation-label) {
    padding: 1px;
    color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 30px !important;
	  box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
	  box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
	  -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
	  -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
  :deep(.VueCarousel-navigation-prev) {
    left: 60px !important;
  }
  :deep(.VueCarousel-navigation-next) {
    right: 70px !important;
  }
  @media (max-width: 450px) {
    :deep(.VueCarousel-navigation-prev) {
      left: 45px !important;
    }
    :deep(.VueCarousel-navigation-next) {
      right: 45px !important;
    }
  }
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';

import testImage1 from '@/assets/images/test_image1.jpg';
import testImage2 from '@/assets/images/test_image2.jpg';

export default {
  name: 'EventCarousel',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      hostname: window.location.hostname,
      assets: {
        testImage1,
        testImage2
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isHome() {
      return this.$route.name === 'home';
    },
    isLiveEvents() {
      return this.$route.name === 'live_events';
    }
  },
  props: {
    perPageCustom: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    gotoEventDetail(eventParam) {
      if (this.$route.name === 'event_detail') {
        localStorage.setItem('eventboxRedirectPath', `/e/${eventParam}`);
        this.$router.push(`/`);
      } else {
        this.$router.push(`/e/${eventParam}`);
      }
    }
  }
};
</script>