<template>
  <div style="background-color: white; background-size: 100% 100%;">
    <div class="my-30 py-40 center" v-if="!liveEventList">
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
    <div v-else>
      <EventCarousel
        :style="isMobile ? '' : 'margin-top: -80px;'"
        :perPageCustom="perPageCustom"
        :items="carouselItems"
      />
      <div :class="`position-relative ${isMobile ? 'my-10 mx-2 vertical-center' : 'mx-auto my-10 w-50 center'}`">
        <i class="position-absolute fa fa-search font-20 color-purple" style="left: 10px; top: 10px;" />
        <input class="w-100 px-20 search-box" style="padding-left: 40px !important; padding-right: 40px !important;" v-model="keyword" />
        <v-btn class="position-absolute" style="right: 10px; top: 8px;" icon small @click="keyword = ''" :disabled="!keyword">
          <i :class="`font-20 flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
        </v-btn>
      </div>
      <div :class="isMobile ? 'm-2' : 'm-10'" v-if="items.length > 0">
        <div :class="isMobile ? 'my-2' : 'm-5'" v-for="(item, index) in items" :key="(item, index)">
          <v-card :class="`box-white p-0 pointer ${isMobile? '' : 'd-flex'}`" @click="gotoEventDetail(item)">
            <div :class="isMobile ? 'w-100' : 'w-25'">
              <v-img class="rounded event-image" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl" />
            </div>
            <div :class="`${isMobile ? 'm-5' : 'mx-10 my-5 w-75'}`">
              <span class="font-bold font-18">{{ item.name }}</span>
              <div class="mt-2 vertical-center">
                <i class="fa fa-calendar-alt font-12 color-blue"></i>
                <span class="ml-2 font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
              </div>
              <div class="mt-2 vertical-center">
                <i class="flaticon2-pin-1 font-12 color-purple"></i>
                <span class="ml-2 font-12 color-purple">{{ item.hideAddress ? $t('private') : getCountryCityFromAddress(item.address) }}</span>
              </div>
              <span :class="`mt-5 font-12 ellipsis-4 ${isMobile ? 'mb-2' : 'mb-10'}`" :title="item.description">{{ item.description }}</span>
              <VueCountdown class="my-5" :time="item.countDownTime" v-if="item.countDownTime && item.countDownTime > 0">
                <template slot-scope="props">
                  <div :class="`${isMobile ? 'w-200px' : 'w-250px'} d-flex justify-content-between box-event-count-down`">
                    <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                      <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.days, 2) }}</span>
                      <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.days > 1 ? 'days' : 'day') }}</span>
                    </div>
                    <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-1px;' : '-2px;'}`">&#58;</span>
                    <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                      <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.hours, 2) }}</span>
                      <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.hours > 1 ? 'hours' : 'hour') }}</span>
                    </div>
                    <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                    <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                      <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.minutes, 2) }}</span>
                      <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.minutes > 1 ? 'minutes' : 'minute') }}</span>
                    </div>
                    <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                    <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                      <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.seconds, 2) }}</span>
                      <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.seconds > 1 ? 'seconds' : 'second') }}</span>
                    </div>
                  </div>
                </template>
              </VueCountdown>
              <div class="mt-5 d-flex justify-content-end" :style="isMobile ? 'padding-bottom: 15px;' : 'position: absolute; bottom: 10px; right: 10px;'">
                <v-btn class="button-small" @click="gotoEventDetail(item)" :disabled="!item.ticketCount">
                  <span class="px-5">{{ $t('ticket') }}</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="my-30 py-40 center" v-else>
        <p>{{ $t('no_data_found') }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .search-box {
    padding: 10px 20px !important;
    border-radius: 20px !important;
    border: 1px solid #45154D !important;
    background-color: white !important;
  }
  .search-box:focus { 
    outline: none !important;
  }
</style>

<script>
import EventCarousel from '@/view/pages/events/EventCarousel.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

import { isFutureTimestamp, getDateStringFromTimestamp, getCountryCityFromAddress, getEventParam, isMobile, zeroPad } from '../../../functions';

import testImage1 from '@/assets/images/test_image1.jpg';
import testImage2 from '@/assets/images/test_image2.jpg';

export default {
  name: 'LiveEvents',
  components: {
    EventCarousel,
    VueCountdown
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    liveEventList() {
      return this.$store.state.liveEventList;
    }
  },
  watch: {
    liveEventList() {
      this.refreshEvent();
    },
    keyword() {
      this.refreshEvent();
    }
  },
  data() {
    return {
      assets: {
        testImage1,
        testImage2
      },
      hostname: window.location.hostname,
      keyword: '',
      perPageCustom: [
        [300, 1],
        [1000, 2],
        [1600, 3]
      ],
      carouselItems: [],
      items: []
    };
  },
  mounted() {
    this.refreshEvent();
  },
  methods: {
    getDateStringFromTimestamp,
    getCountryCityFromAddress,
    zeroPad,
    gotoEventDetail(eventInfo) {
      this.$router.push(`/e/${getEventParam(eventInfo)}`);
    },
    refreshEvent() {
      if (this.liveEventList) {
        const list = this.liveEventList.filter(element => element.name.toLowerCase().includes(this.keyword.toLowerCase()));
        const openList = list.filter(element => !isFutureTimestamp(element.startAt)).sort((a, b) => (a.endAt.seconds > b.endAt.seconds) ? 1 : -1);
        const futureList = list.filter(element => isFutureTimestamp(element.startAt)).sort((a, b) => (a.startAt.seconds > b.startAt.seconds) ? 1 : -1);
        futureList.filter(element => element.startAt && element.displayCountDown === true).forEach(info => {
          info['countDownTime'] = info.startAt.seconds * 1000 - new Date().getTime();
        });
        this.items = openList.concat(futureList);
        const carouselItems = [];
        this.liveEventList.filter(element => element.netSales).forEach(eventInfo => {
          carouselItems.push({
            photoUrl: eventInfo.photoUrl,
            eventParam: getEventParam(eventInfo)
          });
        });
        this.carouselItems = carouselItems.slice(0, Math.min(carouselItems.length, 10));
      } else {
        this.items = [];
        this.carouselItems = [];
      }
    }
  }
};
</script>